import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import CustomButton from 'components/button';

interface PropsLabel {
  alt?: string;
  title?: string;
}

export const Container = styled(Box)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const Group = styled(Box)`
  && {
    margin-bottom: 20px;

    input[type='file'] {
      width: 200%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
  }
`;

export const GroupError = styled(Box)`
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Button = styled(CustomButton)`
  overflow: hidden;
  margin-bottom: 20px;
`;

export const File = styled(Box)`
  height: 50px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.dark};
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: ${(props) => props.theme.shape.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  margin-bottom: 20px;
  cursor: default;
  position: relative;
`;

export const Label = styled(Box)<PropsLabel>`
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  color: ${(props) => props.theme.palette.error.contrastText};
`;

export const ButtonRemove = styled(IconButton)`
  && {
    width: 28px;
    height: 28px;
    background: ${(props) => props.theme.palette.error.main};
    position: absolute;
    top: -10px;
    right: -10px;

    &:hover {
      background: ${(props) => props.theme.palette.error.main};
    }
  }
`;

export const IconAttach = styled(AttachmentOutlinedIcon)`
  margin-right: 10px;
`;

export const IconRemove = styled(CloseOutlinedIcon)`
  && {
    color: ${(props) => props.theme.palette.error.contrastText};
    font-size: 18px;
  }
`;
