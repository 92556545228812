import React, { memo } from 'react';
import { InputCustom } from './styled';

interface Props {
  type?: 'text' | 'password';
  variant?: any;
  color?: 'primary' | 'secondary';
  className?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  label: string;
  name?: string;
  value: string;
  onChange?: any;
  error?: boolean;
  helperText?: any;
  disabled?: boolean;
}

const InputText: React.FC<Props> = ({
  type,
  multiline,
  minRows,
  maxRows,
  variant,
  className,
  color,
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  disabled,
}) => (
  <InputCustom
    type={type || 'text'}
    multiline={multiline || false}
    maxRows={maxRows || ''}
    minRows={minRows || ''}
    variant={variant || 'outlined'}
    className={className || ''}
    color={color}
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    autoComplete="off"
    disabled={disabled}
  />
);

export default memo(InputText);
