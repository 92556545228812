import { useState, useCallback, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api, { axios } from 'service/api';
import { useNotification } from 'hooks/notification';
import { validateUploadFiles } from 'utils';

interface PropsSelect {
  title: string;
  value: string;
}

interface PropsFields {
  name: string;
  city: string;
  state: string;
  optionsStates: PropsSelect[];
  company: string;
  optionsCompanies: PropsSelect[];
  companyRelationship: string;
  optionsCompanyRelationship: PropsSelect[];
  complaint: string;
  attachments: any;
}

interface PropsError {
  city: string;
  state: string;
  company: string;
  companyRelationship: string;
  complaint: string;
  attachment: string;
}

interface PropsConfirm {
  code: string;
  message: string;
  open: boolean;
  anonymous: boolean;
  status: 'loading' | 'success' | 'error' | '';
}

export default function useMakeComplaint() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const initialFields = useCallback(
    () => ({
      name: '',
      city: '',
      state: '',
      optionsStates: [],
      company: '',
      optionsCompanies: [],
      companyRelationship: '',
      optionsCompanyRelationship: [],
      complaint: '',
      attachments: [],
    }),
    [],
  );

  const initialError = useCallback(
    () => ({
      city: '',
      state: '',
      company: '',
      companyRelationship: '',
      complaint: '',
      attachment: '',
    }),
    [],
  );

  const initialConfirm = useCallback(
    () => ({
      open: false,
      anonymous: false,
      code: '',
      status: '',
      message: '',
    }),
    [],
  );

  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [fields, setFields] = useState(initialFields() as PropsFields);
  const [error, setError] = useState(initialError() as PropsError);
  const [confirm, setConfirm] = useState(initialConfirm() as PropsConfirm);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChange = useCallback(({ target: { name, value } }: any) => {
    setFields((oldFields) => ({
      ...oldFields,
      [name]: value,
    }));
    setError((oldErrors) => ({
      ...oldErrors,
      [name]: '',
    }));
  }, []);

  const handleChangeFile = useCallback(
    ({ target: { files, value } }: any) => {
      const file = files[0];

      if (!validateUploadFiles(value)) {
        setError((oldError) => ({
          ...oldError,
          attachment: translate('validation.weAcceptFiles'),
        }));
        openNotification(translate('validation.invalidFileType'), 'error');
        return;
      }

      setError((oldError) => ({
        ...oldError,
        attachment: '',
      }));

      setFields((oldFields) => ({
        ...oldFields,
        attachments: [...oldFields.attachments, file],
      }));
    },
    [openNotification, translate],
  );

  const handleRemoveFile = useCallback((value: any) => {
    const input: any = document.querySelector('input[type="file"]');
    input.value = null;

    setFields((oldFields) => ({
      ...oldFields,
      attachments: oldFields.attachments.filter((item: any) => item !== value),
    }));
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setConfirm({
      ...confirm,
      ...{
        open: false,
      },
    });
  }, [confirm]);

  const validate = useCallback(() => {
    const myError = {
      city: '',
      state: '',
      company: '',
      companyRelationship: '',
      complaint: '',
      attachment: '',
    };

    let hasError = false;

    if (fields.state === '') {
      myError.state = translate('validation.selectState');
      hasError = true;
    }
    if (fields.city === '') {
      myError.city = translate('validation.selectCity');
      hasError = true;
    }
    if (fields.optionsCompanies.length > 0 && fields.company === '') {
      myError.company = translate('validation.selectCompany');
      hasError = true;
    }
    if (fields.optionsCompanyRelationship.length > 0 && fields.companyRelationship === '') {
      myError.companyRelationship = translate('validation.selectCompanyRelationship');
      hasError = true;
    }
    if (fields.complaint === '') {
      myError.complaint = translate('validation.writeComplaint');
      hasError = true;
    }

    setError({ ...myError });

    return hasError;
  }, [fields, translate]);

  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      if (validate()) {
        return;
      }

      setConfirm((oldShowConfirm) => ({
        ...oldShowConfirm,
        ...{
          open: true,
          status: '',
        },
      }));
    },
    [validate],
  );

  const handleConfirm = useCallback(async () => {
    setLoadingForm(true);

    setConfirm((oldConfirm) => ({
      ...oldConfirm,
      status: 'loading',
    }));

    const formData = new FormData();
    formData.append('uuid', process.env.REACT_APP_UUID as string);
    formData.append('complice_name', fields.name);
    formData.append('state_id', fields.state);
    formData.append('city', fields.city);
    formData.append('complice_description', fields.complaint);

    if (fields.optionsCompanies.length > 0) {
      formData.append('company_id', fields.company);
    }
    if (fields.optionsCompanyRelationship.length > 0) {
      formData.append('relationship_company_id', fields.companyRelationship);
    }

    fields?.attachments?.map((item: any, index: number) => {
      formData.append(`file[${index}]`, item);
      formData.append(`file_name[${index}]`, item.name);
      return item;
    });

    try {
      const {
        data: { success, dataComplice },
      } = await api.post('/complice_external', formData);

      if (success) {
        setConfirm((oldConfirm) => ({
          ...oldConfirm,
          ...{
            status: 'success',
            code: dataComplice.token,
            anonymous: fields.name === '',
          },
        }));

        setFields((oldFields) => ({
          ...oldFields,
          ...{
            name: '',
            state: '',
            city: '',
            company: '',
            companyRelationship: '',
            complaint: '',
            attachments: [],
          },
        }));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingForm(false);
    }
  }, [fields]);

  const getDatas = useCallback(async () => {
    try {
      setLoading(true);

      const requests = [
        await api.get('/state'),
        await api.get('/company_extern'),
        await api.get('/relationship_company_extern'),
      ];

      await axios.all(requests).then(
        axios.spread((...responses) => {
          setFields((oldFields) => {
            let optionsStates: any = [];
            let optionsCompanies: any = [];
            let optionsCompanyRelationship: any = [];

            if (responses[0]) {
              const {
                data: { dataState },
              } = responses[0];

              optionsStates = dataState.map((item: any) => ({ title: item.description, value: item.id }));
            }
            if (responses[1]) {
              const {
                data: { dataCompany },
              } = responses[1];

              optionsCompanies = dataCompany.map((item: any) => ({ title: item.name, value: item.id }));
            }
            if (responses[2]) {
              const {
                data: { dataRelationshipCompany },
              } = responses[2];

              optionsCompanyRelationship = dataRelationshipCompany.map((item: any) => ({
                title: item.description,
                value: item.id,
              }));
            }

            return {
              ...oldFields,
              ...{
                optionsStates,
                optionsCompanies,
                optionsCompanyRelationship,
              },
            };
          });
        }),
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    document.title = translate('makeComplaint.head.title');

    getDatas();
  }, [translate, getDatas]);

  return {
    loading,
    loadingForm,
    fields,
    confirm,
    error,
    translate,
    goBack,
    handleChange,
    handleChangeFile,
    handleRemoveFile,
    handleCloseConfirm,
    handleSubmit,
    handleConfirm,
  };
}
