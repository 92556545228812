import { useState, useCallback, useEffect, MouseEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import api from 'service/api';
import { useNotification } from 'hooks/notification';
import { validateUploadFiles } from 'utils';

interface PropsFields {
  code: string;
  comment: string;
  attachments: any;
}

interface PropsError {
  code?: string;
  comment?: string;
  attachment?: string;
}

type Comment = {
  iam: boolean;
  message: string;
  date: string;
  ext?: string;
  url?: string;
};

type Attachment = {
  ext: string;
  url: string;
  file_name: string;
};

type Conclusion = {
  definition: string;
  observation: string;
  attachment?: Attachment | null;
};

type Attachments = {
  images: Attachment[];
  videos: Attachment[];
  audios: Attachment[];
  documents: Attachment[];
};

interface PropsData {
  showComplaint: boolean;
  status: 'initiated' | 'under-analysis' | 'pending' | 'finished' | 'open';
  sentBy: string;
  description: string;
  attachments?: Attachments;
  comments?: Comment[];
  date: string;
  conclusion: Conclusion | null;
}

export default function useMakeComplaint() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { code } = useParams();
  const { openNotification } = useNotification();

  const initialFields = useCallback(
    () => ({
      code: '',
      comment: '',
      attachments: [],
    }),
    [],
  );

  const initialError = useCallback(
    () => ({
      code: '',
      comment: '',
      attachment: '',
    }),
    [],
  );

  const initialData = useCallback(
    () => ({
      showComplaint: false,
      status: '',
      sentBy: '',
      description: '',
      attachments: {
        images: [],
        videos: [],
        audios: [],
        documents: [],
      },
      comments: [],
      date: '',
      conclusion: null,
    }),
    [],
  );

  const [loading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingFormComment, setLoadingFormComment] = useState(false);
  const [fields, setFields] = useState(initialFields() as PropsFields);
  const [error, setError] = useState(initialError() as PropsError);
  const [data, setData] = useState(initialData() as PropsData);

  const getDefinationName = useCallback(
    (value: number) => {
      if (value === 1) {
        return translate('searchComplaint.conclusion.positive');
      }
      if (value === 2) {
        return translate('searchComplaint.conclusion.negative');
      }

      return translate('searchComplaint.conclusion.openInquiry');
    },
    [translate],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChange = useCallback(
    ({ target: { name, value } }: any) => {
      setFields({
        ...fields,
        [name]: value,
      });
      setError((oldError) => ({
        ...oldError,
        [name]: '',
      }));

      if (name === 'code' && value === '') {
        setData(initialData() as PropsData);
      }
    },
    [fields, initialData],
  );

  const handleClearCode = useCallback(() => {
    setFields({
      ...fields,
      code: '',
    });
    setData(initialData() as PropsData);

    navigate(translate('route.search'));
  }, [fields, initialData, navigate, translate]);

  const handleChangeFile = useCallback(
    ({ target: { files, value } }: any) => {
      const file = files[0];

      if (!validateUploadFiles(value)) {
        setError((oldError) => ({
          ...oldError,
          attachment: translate('validation.weAcceptFiles'),
        }));
        openNotification(translate('validation.invalidFileType'), 'error');
        return;
      }

      setError((oldError) => ({
        ...oldError,
        attachment: '',
      }));

      setFields({
        ...fields,
        attachments: [{ file: file, name: file.name }],
      });
    },
    [fields, openNotification, translate],
  );

  const handleRemoveFile = useCallback(
    (value: any) => {
      const input: any = document.querySelector('input[type="file"]');
      input.value = null;

      setFields({
        ...fields,
        attachments: fields.attachments.filter((item: any) => item !== value),
      });
    },
    [fields],
  );

  const validateSearch = useCallback(() => {
    const myError = {
      code: '',
    };

    let hasError = false;

    if (fields.code.length < 5) {
      myError.code = translate('validation.validCode');
      hasError = true;
    }

    setError({ ...myError });

    return hasError;
  }, [fields, translate]);

  const getData = useCallback(
    async (code: string) => {
      setLoadingForm(true);
      try {
        const {
          data: { success, error, dataComplice, dataFiles, dataChatMessageComplice, dataFinalizingComplice },
        } = await api.get(`/complice_external?token=${code}`);

        if (success) {
          setFields((oldFields) => ({
            ...oldFields,
            code,
          }));

          setData((oldData) => ({
            ...oldData,
            ...{
              showComplaint: true,
              status: dataComplice.status,
              sentBy: dataComplice.complice_name,
              description: dataComplice.complice_description,
              attachments: {
                images: dataFiles.filter(
                  (item: any) =>
                    item.ext === 'jpg' ||
                    item.ext === 'jpeg' ||
                    item.ext === 'png' ||
                    item.ext === 'svg' ||
                    item.ext === 'gif',
                ),
                videos: dataFiles.filter((item: any) => item.ext === 'mp4'),
                audios: dataFiles.filter((item: any) => item.ext === 'mp3'),
                documents: dataFiles.filter(
                  (item: any) =>
                    item.ext === 'pdf' ||
                    item.ext === 'xlsx' ||
                    item.ext === 'xls' ||
                    item.ext === 'doc' ||
                    item.ext === 'docx' ||
                    item.ext === 'ppt' ||
                    item.ext === 'pptx' ||
                    item.ext === 'txt',
                ),
              },
              comments: dataChatMessageComplice?.map((item: any) => ({
                iam: Boolean(item.token === code),
                message: item.message,
                ext: item.ext,
                url: item.url,
                date: format(new Date(item.created_at), translate('format.datetime')),
              })),
              date: format(new Date(dataComplice.created_at), translate('format.datetime')),
              conclusion: {
                definition: getDefinationName(dataFinalizingComplice.definition_id),
                observation: dataFinalizingComplice.observation,
                attachment: dataFinalizingComplice.ext
                  ? {
                      ext: dataFinalizingComplice.ext,
                      url: dataFinalizingComplice.url,
                      file_name: dataFinalizingComplice.file_name,
                    }
                  : null,
              },
            },
          }));

          return;
        }
        openNotification(translate(`validation.${error}`), 'error');
      } catch (error: any) {
        const { data } = error;
        openNotification(translate(`validation.${data.error}`), 'error');
      } finally {
        setLoadingForm(false);
      }
    },
    [getDefinationName, openNotification, translate],
  );

  const handleSubmitSearch = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      if (validateSearch()) {
        return;
      }

      navigate(translate('route.code').replace(':code', fields.code));
    },
    [fields, validateSearch, navigate, translate],
  );

  const createComment = useCallback(async () => {
    if (fields.comment === '' && fields.attachments.length === 0) {
      openNotification(translate(`validation.emptyComment`), 'error');
      return;
    }

    setLoadingFormComment(true);

    const formData = new FormData();
    formData.append('token', fields.code);
    formData.append('message', fields.comment);

    if (fields.attachments.length > 0) {
      formData.append('file', fields.attachments[0].file);
      formData.append('file_name', fields.attachments[0].name);
    }

    try {
      const {
        data: { success, message, error, chatMessageData, dataUpload },
      } = await api.post('/complice_external/message', formData);

      if (success) {
        const newComment = {
          iam: true,
          message: chatMessageData.message,
          ext: dataUpload?.ext || '',
          url: dataUpload?.url || '',
          date: format(new Date(chatMessageData.created_at), translate('format.datetime')),
        };

        setData((oldData) => ({ ...oldData, comments: [...(oldData.comments as []), newComment] }));

        setFields({
          ...fields,
          ...{
            comment: '',
            attachments: [],
          },
        });

        openNotification(translate(`validation.${message}`), 'success');

        return;
      }

      openNotification(translate(`validation.${error}`), 'error');
    } catch (error: any) {
      const { data } = error;
      openNotification(translate(`validation.${data.error}`), 'error');
    } finally {
      setLoadingFormComment(false);
    }
  }, [fields, openNotification, translate]);

  const handleSubmitComment = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      createComment();
    },
    [createComment],
  );

  useEffect(() => {
    document.title = translate('searchComplaint.head.title');

    if (code !== undefined) {
      setData(initialData() as PropsData);
    }
  }, [code, translate, initialData]);

  useEffect(() => {
    if (code) {
      getData(code);
      return;
    }
    setData(initialData() as PropsData);
    setFields(initialFields() as PropsFields);
  }, [code, translate, getData, initialData, initialFields]);

  return {
    loading,
    loadingForm,
    loadingFormComment,
    fields,
    error,
    data,
    translate,
    goBack,
    handleChange,
    handleClearCode,
    handleChangeFile,
    handleRemoveFile,
    handleSubmitSearch,
    handleSubmitComment,
  };
}
